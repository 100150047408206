import * as React from "react";
import {graphql, Link} from "gatsby";
import {CSSProperties, useEffect, useRef, useState} from "react";
import htmlFromField from '../lib/htmlFromField'
import {Helmet} from "react-helmet";
import Navigation from "../components/Navigation";
import {getBackgroundColorFromName, getContrastColorFromName} from "../components/Helpers/Color";
import Logo from "../components/Logo";
import Breadcrumb from "../components/Breadcrumb";
import {getPageTitle} from "../components/Helpers/Meta";
import Fade from "../transitions/Fade";
import {off, on} from "../components/Events";
import Image from "../components/Image";
import TransitionLink  from 'gatsby-plugin-transition-link'
import ScrollIndicator from "../components/ScrollIndicator";

export const SERVICE_DETAIL_QUERY = graphql`
  query ServiceDetailQuery($id: String!) {
    content: contentfulServiceDetailPage(
      id: { eq: $id }
    ) {
        metatitle
        textBottomLeft1
        textTopRight
        flipTextTopRight1
        flipTextBottomLeft1
        backgroundColor
        sideQuote
        textLeft {
            childMarkdownRemark {
              html
            }
        }
        textRight {
            childMarkdownRemark {
              html
            }
        }
        imageRight {
          file {
            contentType
            url
          }
          gatsbyImageData(width: 1920, placeholder: BLURRED, formats: [AUTO, WEBP])
          title
        }
    }
  }
`;


const ServiceDetailTemplate = ({data}) => {
    const {
        content: {
            metatitle,
            textLeft,
            textRight,
            imageRight,
            textBottomLeft1,
            textTopRight,
            flipTextTopRight1,
            flipTextBottomLeft1,
            backgroundColor,
            sideQuote
        }
    } = data;

    const contentRef = useRef(null);
    const style = {'--contrast-color': getContrastColorFromName(backgroundColor)} as CSSProperties;
    const hidePage = () => {
        Fade.animate([
            { target: '.o-layout', type: Fade.TYPE_OPACITY },
            { target: '.c-service-detail--cornerText', type: Fade.TYPE_OPACITY },
            { target: '.c-logo', type: Fade.TYPE_OPACITY },
            { target: '.c-breadcrumbs', type: Fade.TYPE_OPACITY },
        ])
    };

    useEffect(() => {
        on('page:out', hidePage)

        return () => {
            off('page:out', hidePage)
        }
    }, [])

    const [isLoaded, setIsLoaded] = useState(false);
    useEffect(() => {setTimeout(() => {setIsLoaded(true)}, 100)}, []);

    return (
        <>
            <Logo />
            <Navigation invert={backgroundColor.toLowerCase() === 'blauw'}/>
            <Helmet>
                <title>{getPageTitle({title: metatitle})}</title>
                <style>
                    {`body { background-color: ${getBackgroundColorFromName(backgroundColor)} }`}
                </style>
            </Helmet>
            <ScrollIndicator />
            <Breadcrumb text={metatitle} />

            <div className={`o-layout o-layout--full-page@desk ${isLoaded ? 'e-active--delay' : ''} ${backgroundColor.toLowerCase() === 'blauw' ? 'e-blue' : ''}`} style={style}>

                <div className={"o-layout u-visually-hidden@desk c-service-detail--mobile-header "} style={style}>
                        <div className={"u-colspan-12 u-rowspan-m1"} />
                        <div className={"u-colspan-12 c-service-detail--mobile-header__spacer"}>
                            <div className={" o-layout u-columns-24"}>
                                <div className={"u-colspan-2 u-colspan-4@desk u-colspan-6@wall"}/>
                                <div className={"u-colspan-12 c-breadcrumb-link"}><TransitionLink to={"/diensten"}
                                                                                entry={{delay: Fade.anim_delay}}
                                                                                exit={{
                                                                                    length: Fade.anim_length,
                                                                                    trigger: (details) => Fade(details)}}
                                                                      className={"u-font-family--secondary"}>&lt;- Alle
                                    diensten</TransitionLink></div>
                                <div className={"u-colspan-2 u-colspan-4@desk u-colspan-6@wall"}/>
                            </div>
                        </div>

                    <div className={"u-colspan-6 c-service-detail--mobile-header__text u-font-weight--black u-font-family--secondary "}>
                        <div className={`c-service-detail--header ${flipTextBottomLeft1 ? 'flipped' : ''}`}>
                            <span>{textBottomLeft1}</span>
                        </div>
                    </div>
                    <div className={"u-colspan-6  c-service-detail--mobile-header__text  u-font-weight--black u-font-family--secondary second-child"}>
                        <div className={`c-service-detail--header ${flipTextBottomLeft1 ? 'flipped' : ''}`}>
                            <span>{textTopRight}</span>
                        </div>
                    </div>
                </div>

                <div
                    className={"o-layout o-layout--full-page@desk u-columns-24 o-two-column-text c-service-detail--container"}
                    ref={contentRef}>
                    <div className={"u-colspan-24 o-layout u-columns-24 c-service-detail--spacer u-hidden@until-desk"}/>

                    <div className={"u-colspan-24 o-layout u-columns-24 o-layout--stretch"}>
                        <div className={"u-colspan-2 u-colspan-4@desk u-colspan-6@wall"}>
                            <div className={"o-layout c-service-detail__sideText-layout"}>
                                <div className={"u-colspan-12"} />
                                <div className={"u-colspan-8"} />
                                <div className={"u-colspan-4 c-service-detail__sideText"}>
                                    <p className={"u-hidden@until-desk e-effect e-fade e-move--down-gentle"}>{sideQuote}</p>
                                </div>
                            </div>
                        </div>
                        <div className={"u-colspan-20 u-colspan-16@desk u-colspan-12@wall"}>
                            <div className={"o-layout o-layout--gap-large u-typo--content"}>
                                {/*Breadcrumb*/}
                                <div className={"u-colspan-12 u-hidden@until-desk c-breadcrumb-link"}>
                                    <TransitionLink to={"/diensten"}
                                                    entry={{delay: Fade.anim_delay}}
                                                    exit={{
                                                        length: Fade.anim_length,
                                                        trigger: (details) => Fade(details)}}
                                                    className={"u-font-family--secondary  e-effect e-fade"}>&lt;- Alle diensten</TransitionLink>
                                </div>

                                <div className={"u-colspan-12 u-hidden@desk c-service-detail__quote"}>{sideQuote}</div>
                                <div className={"u-colspan-12 u-colspan-6@desk e-effect e-fade e-move--up-gentle"} dangerouslySetInnerHTML={{
                                    __html: htmlFromField(textLeft)
                                }}/>
                                <div className={"u-colspan-12 u-colspan-6@desk e-effect e-fade e-move--up-gentle"} dangerouslySetInnerHTML={{
                                    __html: htmlFromField(textRight)
                                }}/>
                            </div>
                        </div>
                        <div className={"u-colspan-2 u-colspan-4@desk u-colspan-6@wall"}/>
                    </div>


                    {/*Mobile image*/}
                    <div className={"u-colspan-24 o-layout u-columns-24 o-layout--stretch c-service-detail--mobile-image u-hidden@desk"}>
                        <div className={"u-colspan-2 u-colspan-4@desk u-colspan-6@wall"}/>
                        <div className={"u-colspan-20 u-colspan-16@desk u-colspan-12@wall"}>
                            <Image image={imageRight}/>
                        </div>
                        <div className={"u-colspan-2 u-colspan-4@desk u-colspan-6@wall"}/>
                    </div>


                    <div className={"u-colspan-24 o-layout u-columns-24 c-service-detail--bottom-content u-hidden@until-desk"}>
                        <div className={"u-colspan-2 u-colspan-4@desk u-colspan-6@wall"}/>
                        <div
                            className={"u-colspan-20 u-colspan-16@desk u-colspan-12@wall c-service-detail--bottom__full-height"}>
                            <div
                                className={"o-layout o-layout--gap-large u-typo--content c-service-detail--bottom__full-height"}>
                                <div className={"u-colspan-12 u-colspan-6@desk"}/>
                                <div className={"u-colspan-12 u-colspan-6@desk c-service-detail--bottom_flex e-effect e-fade"}>
                                    <Image image={imageRight}/>
                                </div>
                            </div>
                        </div>
                        <div className={"u-colspan-2 u-colspan-4@desk u-colspan-6@wall"}/>
                    </div>

                </div>
            </div>

            <div
                className={`${isLoaded ? 'e-active--delay' : ''} u-font-weight--black u-font-family--secondary c-service-detail--cornerText c-service-detail--cornerText__bottomLeft u-hidden@until-desk`}
                style={style}>
                <div className={` e-effect e-fade c-service-detail--textfit ${flipTextBottomLeft1 ? 'flipped' : ''}`}>
                    <span>{textBottomLeft1}</span>
                </div>
            </div>


            <div
                className={`${isLoaded ? 'e-active--delay' : ''} u-font-weight--black u-font-family--secondary c-service-detail--cornerText c-service-detail--cornerText__topRight u-hidden@until-desk`}
                style={style}>
                <div className={` e-effect e-fade c-service-detail--textfit ${flipTextTopRight1 ? 'flipped' : ''}`}>
                    <span>{textTopRight}</span>
                </div>
            </div>
        </>
    );
};

export default ServiceDetailTemplate;
